export const environment = {
  production: true,
  apiUrl: 'https://dev-api.walkdotin.com/api/',
  automationsApiUrl: 'https://dev-api.walkdotin.com/automations/v1/',
  shortUrl: 'https://dev-api.walkdotin.com/',
  firebaseApiKey: 'AIzaSyCjAcipxB3Ka7M7vl-KSxKqdvIsGbnRf_Q',
  firebaseAuthDomain: 'walkiniq-b7a20.firebaseapp.com',
  firebaseDatabaseURL: 'https://walkiniq-b7a20.firebaseio.com',
  firebaseProjectId: 'walkiniq-b7a20',
  firebaseStorageBucket: 'walkiniq-b7a20.firebasestorage.app',
  firebaseMessagingSenderId: '528120173697',
  firebaseAppId: '1:528120173697:web:7bf9b8819c0d917c566980',
  firebaseMeasurementId: 'G-RJSECJ9W12',
  firebasePublicVapidKey:
    'BAFhqziC_lXdD86-L4-x96YJPDXREh-V6u7kHDekuAZ0owdNl3LRcRwcpLjnlrEw8Mf-RVccq9yuaAxDh3AFMkA'
};
